
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/under-construction",
      function () {
        return require("private-next-pages/under-construction.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/under-construction"])
      });
    }
  