// @flow strict-local

import React, { type Node } from 'react';

import { getPageProps, type PageProps } from '../src/app/getPageProps';
import UnderConstruction from '../src/homepage/UnderConstruction';

export default function Root(props: PageProps): Node {
  return <UnderConstruction baseDomain={props.baseDomain} />;
}

export const getServerSideProps = getPageProps;
