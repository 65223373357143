// @flow strict-local

import React, { type Node } from 'react';
import { fbt } from 'fbt';
import Link from 'next/link';

type Props = {
  baseDomain: string,
};

export default function UnderConstruction({ baseDomain }: Props): Node {
  const protocol = baseDomain.startsWith('localhost') ? 'http' : 'https';
  const enUrl = `${protocol}://${baseDomain}/under-construction`;
  const csCzUrl = `${protocol}://cs-cz.${baseDomain}/under-construction`;

  return (
    <div sxt="bg-cool-gray-300 h-screen flex items-center justify-center">
      <main>
        <h2 sxt="font-thin tracking-tight bg-clip-text text-transparent animate-hue bg-gradient-to-br from-orange-400 via-red-500 to-pink-500 sm:text-5xl md:text-9xl">
          <fbt desc="welcome message">Workin&apos; on it...</fbt>
        </h2>
      </main>
      <div sxt="absolute top-0 right-0 text-gray-500 text-sm p-4">
        <Link href={enUrl}>
          <a href={enUrl} sxt="hover:underline">
            English
          </a>
        </Link>
        &nbsp;|&nbsp;
        <Link href={csCzUrl}>
          <a href={csCzUrl} sxt="hover:underline">
            Čeština
          </a>
        </Link>
      </div>
    </div>
  );
}
